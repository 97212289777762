module.exports = [{
      plugin: require('../plugins/gatsby-source-json/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1091637771215383","version":"v6.0","xfbml":true,"cookie":true,"includeInDevelopment":false,"debug":false,"language":"en_US"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDH8SMM","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Metal Against Coronavirus","short_name":"Metal Against Coronavirus","start_url":"https://metalagainstcoronavirus.com","theme_color":"#000000","display":"standalone","icon":"src/images/favicon/favicon-32x32.png","icons":[{"src":"src/images/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e87180a3a80aabc29708b754f9408b4e"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
